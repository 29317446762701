/*////////////////////////
    Table of Contents
    1. Global Styles
    2. Typography
    3. Layout
        3.1 Reusables
        3.2 Other
    4. Areas
        4.1 Login form
    5. Media Queries
////////////////////////*/
/* ------------------------------- 1. Global Styles -------------------------------- */

/* ------------------------------- 2. Typography ----------------------------------- */

/* ------------------------------- 3. Layout ----------------------------------- */

.main {
    padding: 0 20px 0 20px;
    min-height: 100vh;

    /*    !*Fix for mobile keyboard issue*!*/
    background: url("../../assets/login_registration_background.jpeg") no-repeat center top;
    background-size: 385% auto;

    display: flex;
    justify-content: center;
    align-items: center;
}

/* ------------------------------- 4. Areas ---------------------------------------- */

/* --------------- 4.1 Login form ------------------------- */

.form-card {
    padding: 30px 20px 30px 20px;
    flex-basis: 100%;
    max-width: 500px;
    min-width: 280px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;
    background-color: rgba(0, 0, 0, 0.62);
}

.h1 {
    margin-bottom: 30px;
    font-weight: 800;
    font-size: 35px;
    text-align: center;
    color: hsl(0, 0%, 90%);
}

.form {
    display: flex;
    flex-direction: column;
    min-width: 100%;
}

.login-registration {
    height: 40px;
    border-radius: 8px;
    border-color: transparent;
    margin-bottom: 25px;
    padding: 10px;
    font-size: 16px;
    min-width: 100%;
}

.login-registration::placeholder {
    font-size: 16px;
}

.error {
    text-align: center;
    color: red;
}


/* ------------------------------- 5. Media Queries -------------------------------- */

@media screen and (min-width: 576px) {
    .main {
        padding: 100px 20px 0 20px;
        min-height: 100vh;

        /*    !*Fix for mobile keyboard background jump issue*!*/
        background: url("../../assets/login_registration_background.jpeg") no-repeat center top fixed;
        background-size: cover;

        display: flex;
        justify-content: center;
        align-items: flex-start;
    }
}