/*////////////////////////
    Table of Contents
    1. Global Styles
    2. Typography
    3. Layout
        3.1 Reusables
        3.2 Other
    4. Areas
        4.1 Title
        4.2 Contendcard total drinking time
        4.3 Contentcard session history
    5. Media Queries
////////////////////////*/
/* ------------------------------- 1. Global Styles -------------------------------- */
/* ------------------------------- 2. Typography ----------------------------------- */
/* ------------------------------- 3. Layout --------------------------------------- */
/* --------------- 3.1 Reusables ----------------------- */
/* --------------- 3.2 Other --------------------------- */
/* ------------------------------- 4. Areas ---------------------------------------- */

/* --------------- 4.1 Title ------------------------- */


.h3 {
    align-self: center;
    margin: 20px 0 5px 0;
}


/* --------------- 4.2 Contentcard total drinking time ------------------- */

.p {
    font-weight: 300;
    text-align: center;
}

.time {
    color: hsl(0, 100%, 50%);
    font-weight: 700;
}

/* --------------- 4.3 Contentcard session history --------------------------- */

.table {
    border-collapse: collapse;
    width: 100%;
    max-width: 500px;
}

.thead {
    position: sticky;
    top: 0;
    vertical-align: bottom;
    height: 30px;

    background-color: white;
}

.td {
    border-bottom: solid red 1px;
    text-align: center;
    padding: 3px;
    font-size: 12px;
    font-weight: 400;
}

.th {
    border-bottom: 1px red solid;
    font-weight: 500;
    text-align: center;
    padding: 3px;
    font-size: 12px;
    color: red;
}

.no-data-message {
    font-weight: 300;
    margin-top: 10px;
}

.error {
    text-align: center;
    color: red;
}

/* ------------------------------- 5. Media Queries -------------------------------- */

