@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Display:wght@300;400;500;600;700;800&display=swap');

/*////////////////////////
    Table of Contents
    1. Global Styles
    2. Typography
    3. Layout (reusables)
////////////////////////*/
/* ------------------------------- 1. Global Styles -------------------------------- */

/*Global reset CSS*/
* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}


/*Global background color*/
body {
    background-color: hsl(0, 0%, 93%);
}

/*!* ------------------------------- 2. Typography ----------------------------------- *!*/

* {
    font-family: 'Red Hat Display', sans-serif, Verdana, Helvetica, Arial;
    font-weight: 400;
}

/*!* ------------------------------- 3. Layout (reusables) ----------------------------------- *!*/