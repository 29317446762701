/*////////////////////////
    Table of Contents
    1. Global Styles
    2. Typography
    3. Layout
        3.1 Reusables
        3.2 Other
    4. Areas
        4.1 Select element
    5. Media Queries
////////////////////////*/
/* ------------------------------- 1. Global Styles -------------------------------- */
/* ------------------------------- 2. Typography ----------------------------------- */
/* ------------------------------- 3. Layout --------------------------------------- */
/* --------------- 3.1 Reusables ----------------------- */
/* --------------- 3.2 Other --------------------------- */
/* ------------------------------- 4. Areas ---------------------------------------- */
/* --------------- 4.1 Select element ------------------------- */

.select {
    margin-bottom: 25px;
}

/* ------------------------------- 5. Media Queries -------------------------------- */