/*////////////////////////
    Table of Contents
    1. Global Styles
    2. Typography
    3. Layout
        3.1 Reusables
        3.2 Other
    4. Areas
        4.1 Error
    5. Media Queries
////////////////////////*/
/* ------------------------------- 1. Global Styles -------------------------------- */
/* ------------------------------- 2. Typography ----------------------------------- */
/* ------------------------------- 3. Layout --------------------------------------- */
/* --------------- 3.1 Reusables ----------------------- */
/* --------------- 3.2 Other --------------------------- */
/* ------------------------------- 4. Areas ---------------------------------------- */
/* --------------- 4.1 Error ------------------------- */

.error {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

/* ------------------------------- 5. Media Queries -------------------------------- */