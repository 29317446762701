/*////////////////////////
    Table of Contents
    1. Global Styles
    2. Typography
    3. Layout
        3.1 Reusables
        3.2 Other
    4. Areas
        4.1 Top section
        4.2 Bottom section
    5. Media Queries
////////////////////////*/
/* ------------------------------- 1. Global Styles -------------------------------- */
/* ------------------------------- 2. Typography ----------------------------------- */
/* ------------------------------- 3. Layout --------------------------------------- */
/* --------------- 3.1 Reusables ----------------------- */
/* --------------- 3.2 Other --------------------------- */
/* ------------------------------- 4. Areas ---------------------------------------- */

/* --------------- 4.1 Top section ------------------------- */

.top-section {
    padding-top: 10px;
    flex-basis: 40%;
    max-width: 280px;
    align-items: center;
    align-self: center;

    display: flex;
}

.podium-container {
    flex-basis: 33.33%;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
}

.profile-picture-container {
    width: 80px;
    height: 80px;
}

.profile-picture {
    width: 100%;
    height: 100%;
    border-radius: 50%;

}

.name {
    text-align: center;
    font-size: 14px;
}

.time {
    text-align: center;
    font-size: 14px;
}

.crown {
    width: 30px;
}

#first-place-container {
    justify-content: flex-start;
    max-width: 33.33%;
    max-height: 100%;
}

#name-first-place {
    font-size: 16px;
}

#profile-picture-container-first-place {
    width: 120px;
    height: 120px;
    z-index: 0;
}

#profile-picture-first-place {
    border: solid hsl(51, 100%, 50%) 3px;
    box-shadow: 2px 0px 36px -3px rgba(209,194,29,0.50);
}

#profile-picture-second-place {
    border: solid hsl(0, 0%, 75%) 3px;
    box-shadow: 2px 0px 36px -3px rgba(161,161,161,0.5);
}

#profile-picture-third-place {
    border: solid hsl(40, 50%, 47%) 3px;
    box-shadow: 2px 0px 36px -3px rgba(199,143,32,0.5);
}

/* --------------- 4.2 Bottom section ------------------------- */

.bottom-section {
    flex-basis: 60%;
    margin-top: 10px;
    width: 280px;
    align-self: center;
    overflow-y: scroll;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.bottom-section::-webkit-scrollbar {
    width: 0;
    height: 0;
}

.shadow-top {
    min-height: 10px;
    width: 100%;
    background: rgb(255,255,255);
    background: linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(237,237,237,1) 50%);
    position: sticky;
    top: 0;
}

.shadow-bottom {
    min-height: 10px;
    width: 100%;
    background: rgb(237,237,237);
    background: linear-gradient(0deg, rgba(237,237,237,1) 50%, rgba(255,255,255,0) 100%);
    position: sticky;
    bottom: 0;
}

/* ------------------------------- 5. Media Queries -------------------------------- */

