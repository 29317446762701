/*////////////////////////
    Table of Contents
    1. Global Styles
    2. Typography
    3. Layout
        3.1 Reusables
        3.2 Other
    4. Areas
        4.1 Title
        4.2 Contentcard session timer
        4.3 Wronglocation card
        4.4 Workaround card
        4.5 Contentcard active drinkers content
    5. Media Queries
////////////////////////*/
/* ------------------------------- 1. Global Styles -------------------------------- */
/* ------------------------------- 2. Typography ----------------------------------- */
/* ------------------------------- 3. Layout --------------------------------------- */
/* --------------- 3.1 Reusables ----------------------- */
/* --------------- 3.2 Other --------------------------- */
/* ------------------------------- 4. Areas ---------------------------------------- */

/* --------------- 4.1 Title ------------------------- */

.h3 {
    align-self: center;
    margin: 20px 0 5px 0;
}

/* --------------- 4.2 Contentcard session timer ------------------- */

.p {
    font-weight: 300;
}

.button {
    margin-top: 20px;
    width: fit-content;
    padding: 10px 20px 10px 20px;
    border-radius: 8px;
    border-style: none;
    font-size: 16px;
    color: hsl(61 100% 50%);
    background: hsl(0, 100%, 50%);
    box-shadow: 0 4px 15px #360101;
}

.button:disabled {
    color: hsla(61, 100%, 50%, 0.47);
    background: hsla(0, 100%, 50%, 0.45);
}

.button:hover {
    cursor: pointer;
}

.counter {
    font-size: 24px;
    font-weight: 500;
}

.error {
    margin-top: 10px;
    text-align: center;
    color: red;
}

/* --------------- 4.3 Wronglocation card ------------------- */

.wrong-location {
    padding: 10px 10px 10px 10px;
    top: -1px;
    left: -1px;
    right: -1px;
    min-height: 140px;
    background-color: rgb(255, 255, 255);
    border-radius: 8px;
    border: solid rgba(255, 0, 0, 0.59) 2px;
    position: absolute;
    text-align: center;
    z-index: 1;

    display: flex;
    flex-direction: column;
    justify-content: center;
}

.button-container {
    min-width: 100%;
    margin-top: 10px;

    display: flex;
    justify-content: center;
}

.button-wrong-location {
    padding: 2px;
}

.button-wrong-location:first-child {
    margin-right: 20px;
}

/* ------------------------------- 4.4 Workaround card -------------------------------- */

.workaround {
    padding: 10px 10px 10px 10px;
    width: 250px;
    top: 300px;
    left: 50%;
    transform: translate(-50%);
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 8px;
    border: dashed rgba(255, 115, 0, 0.49) 1px;
    position: absolute;
    text-align: center;

    display: flex;
    flex-direction: column;
    justify-content: center;
}

.workaround-text {
    font-size: 12px;
}

/* ------------------------------- 4.5 Contentcart active drinkers content -------------------------------- */

.table {
    border-collapse: collapse;
    width: 100%;
    max-width: 500px;
}

.thead {
    position: sticky;
    top: 0;
    vertical-align: bottom;
    height: 30px;

    background-color: white;
}

.td {
    border-bottom: solid red 1px;
    text-align: center;
    padding: 3px;
    font-size: 12px;
    font-weight: 400;
}

.th {
    border-bottom: 1px red solid;
    font-weight: 500;
    text-align: center;
    padding: 3px;
    font-size: 12px;
    color: red;
}

.no-data-message {
    font-weight: 300;
    margin-top: 10px;
    text-align: center;
}

/* ------------------------------- 5. Media Queries -------------------------------- */