/*////////////////////////
    Table of Contents
    1. Global Styles
    2. Typography
    3. Layout
        3.1 Reusables
        3.2 Other
    4. Areas
        4.1 App title
        4.2 Login form
        4.3 Registration paragraph
        4.4 Reset password popup
    5. Media Queries
////////////////////////*/
/* ------------------------------- 1. Global Styles -------------------------------- */

/* ------------------------------- 2. Typography ----------------------------------- */

/* ------------------------------- 3. Layout ----------------------------------- */

.main {
    padding: 0 20px 0 20px;
    min-height: 100vh;

    /*    !*Fix for mobile keyboard issue*!*/
    background: url("../../assets/login_registration_background.jpeg") no-repeat center top;
    background-size: 385% auto;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

/* ------------------------------- 4. Areas ---------------------------------------- */

/* --------------- 4.1 App title ------------------------- */

.h1 {
    font-weight: 800;
    font-size: 48px;
}

/* --------------- 4.2 Login form ------------------------- */

.form-card {
    flex-basis: 300px;
    margin-top: 50px;
    padding: 30px 20px 5px 20px;
    width: 100%;
    max-width: 500px;
    min-width: 280px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;
    background-color: rgba(0, 0, 0, 0.62);
}

.form {
    display: flex;
    flex-direction: column;
    min-width: 100%;
}

.login-registration {
    height: 40px;
    border-radius: 8px;
    border-color: transparent;
    margin-bottom: 25px;
    padding: 10px;
    font-size: 16px;
}

.login-registration::placeholder {
    font-size: 16px;
}

/* --------------- 4.3 Registration paragraph ------------------------- */

.p {
    text-align: center;
    font-size: 14px;
    font-weight: 300;
    color: hsl(0, 0%, 90%);
}

.p a {
    color: hsl(61 100% 50%);
    text-decoration: none;
}

#password-reset {
    color: hsl(61 100% 50%);
}

#password-reset:hover {
    cursor: pointer;
}

.error {
    text-align: center;
    color: red;
}

/* --------------- 4.3 Registration paragraph ------------------------- */

.reset-password-popup {
    padding: 10px 10px 10px 10px;
    width: 80%;
    max-width: 500px;
    top: 50%;
    height: 200px;
    background-color: rgb(255, 255, 255);
    border-radius: 8px;
    border: solid rgba(255, 0, 0, 0.59) 2px;
    position: absolute;
    text-align: center;

    display: flex;
    flex-direction: column;
    justify-content: center;
}

.reset-password-popup p {
    font-size: 14px;
}

.reset-password-popup p:nth-child(2) {
    margin: 5px 0 20px 0;
    font-weight: 300;
}

.reauth {
    padding: 5px;
    width: 100%;
    font-size: 14px;
    border-radius: 8px;
    border-width: 1px;
}

.button-container {
    margin-top: 20px;
    display: flex;
    justify-content: center;
}

.auth-button {
    padding: 2px;
}

.auth-button:first-child {
    margin-right: 10px;
}

.auth-button:nth-child(2) {
    margin-left: 10px;
}

.error {
    text-align: center;
    color: red;
}

/* ------------------------------- 5. Media Queries -------------------------------- */

@media screen and (min-width: 576px) {
    .main {
        padding: 100px 20px 0 20px;

        /*    !*Fix for mobile keyboard background jump issue*!*/
        background: url("../../assets/login_registration_background.jpeg") no-repeat center top fixed;
        background-size: cover;

        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        min-height: 100vh;
    }
}

.h1 {
    font-size: 48px;
}