/*////////////////////////
    Table of Contents
    1. Global Styles
    2. Typography
    3. Layout
        3.1 Reusables
        3.2 Other
    4. Areas
        4.1 Icons
    5. Media Queries
////////////////////////*/
/* ------------------------------- 1. Global Styles -------------------------------- */
/* ------------------------------- 2. Typography ----------------------------------- */
/* ------------------------------- 3. Layout --------------------------------------- */
/* --------------- 3.1 Reusables ----------------------- */
/* --------------- 3.2 Other --------------------------- */
/* ------------------------------- 4. Areas ---------------------------------------- */
/* --------------- 4.1 Icons ------------------------- */

.figure {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: fit-content;
}

.figure:first-child {
    margin-right: 5px;
}
.figure:last-child {
    margin-left: 5px;
}

.icon {
    max-width: 25px;
}

.icon-text {
    font-size: 10px;
}

/* ------------------------------- 5. Media Queries -------------------------------- */