/*////////////////////////
    Table of Contents
    1. Global Styles
    2. Typography
    3. Layout
        3.1 Reusables
        3.2 Other
    4. Areas
        4.1 Scoreboard tile
////////////////////////*/
/* ------------------------------- 1. Global Styles -------------------------------- */

/* ------------------------------- 2. Typography ----------------------------------- */

/* ------------------------------- 3. Layout --------------------------------------- */

/* ------------------------------- 4. Areas ---------------------------------------- */

/* --------------- 4.1 Scoreboard tile ------------------------- */

.tile {
    padding: 5px 5px 5px 5px;
    margin: 3px 0 3px 0;

    width: 100%;

    border: solid hsl(54, 100%, 50%) 1px;
    border-radius: 8px;
    background: rgb(255,0,0);
    background: linear-gradient(125deg, rgba(255,0,0,0.7) 30%, rgba(255,218,0,0.7) 32%, rgba(255,218,0,0.7) 68%, rgba(255,0,0,0.7) 70%);

    display: flex;
    justify-content: space-between;
}

.place {
    font-size: 14px;
    font-weight: 500;
}

.p {
    font-size: 14px;
    font-weight: 400;
}

.task-status {
    position: absolute;
    right: 10px;
    font-size: 10px;
}

.task-added-date, .completed-by {
    font-size: 10px;
}