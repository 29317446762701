/*////////////////////////
    Table of Contents
    1. Global Styles
    2. Typography
    3. Layout
        3.1 Reusables
        3.2 Other
    4. Areas
        4.1 Content card (standard)
        4.2 Conent card (tables)
        4.3 Content card (use max height)
        4.4 remove scrollbar
    5. Media Queries
////////////////////////*/
/* ------------------------------- 1. Global Styles -------------------------------- */
/* ------------------------------- 2. Typography ----------------------------------- */
/* ------------------------------- 3. Layout --------------------------------------- */
/* --------------- 3.1 Reusables ----------------------- */
/* --------------- 3.2 Other --------------------------- */
/* ------------------------------- 4. Areas ---------------------------------------- */
/* --------------- 4.1 Content card (tasks) ------------------------- */


/* --------------- 4.1 Content card (standard) ------------------------- */

.standard {
    padding: 20px 20px 20px 20px;
    border: solid hsl(61, 100%, 50%) 1px;
    border-radius: 8px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.4);
    background-color: hsl(0, 0%, 100%);
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;
}

/* --------------- 4.2 Content card (tables) ------------------------- */

.table {
    padding: 0 20px 20px 20px;
    overflow-y: scroll;
    border-radius: 8px;
    border: solid hsl(61, 100%, 50%) 1px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.4);
    background-color: hsl(0, 0%, 100%);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

/* --------------- 4.3 Content card (use max-height) ------------------------- */

.max-height {
    padding: 20px 20px 20px 20px;
    border: solid hsl(61, 100%, 50%) 1px;
    border-radius: 8px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.4);
    background-color: hsl(0, 0%, 100%);
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
}

/* --------------- 4.8 Scrollbar ------------------------- */

.tasks::-webkit-scrollbar, .table::-webkit-scrollbar {
    width: 0;
    height: 0;
}