/*////////////////////////
    Table of Contents
    1. Global Styles
    2. Typography
    3. Layout
        3.1 Reusables
        3.2 Other
    4. Areas
        4.1 Reauthentication popup
    5. Media Queries
////////////////////////*/
/* ------------------------------- 1. Global Styles -------------------------------- */
/* ------------------------------- 2. Typography ----------------------------------- */
/* ------------------------------- 3. Layout --------------------------------------- */
/* --------------- 3.1 Reusables ----------------------- */
/* --------------- 3.2 Other --------------------------- */
/* ------------------------------- 4. Areas ---------------------------------------- */
/* --------------- 4.1 Header ------------------------- */
/* --------------- 4.2 Introduction ------------------- */
/* --------------- 4.3 Work --------------------------- */
/* --------------- 4.4 Services------------------------ */
/* --------------- 4.5 Contact ------------------------ */
/* --------------- 4.6 Footer ------------------------- */

/* ------------------------------- 4.1 Reauthentication popup -------------------------------- */

.warning-popup {
    padding: 10px 10px 10px 10px;
    width: 80%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgb(255, 255, 255);
    border-radius: 8px;
    border: solid rgba(255, 0, 0, 0.59) 2px;
    position: absolute;
    text-align: center;

    display: flex;
    flex-direction: column;
    justify-content: center;
}

.warning-popup p {
    font-size: 14px;
}

.warning-popup p:nth-child(2) {
    margin: 5px 0 20px 0;
    font-weight: 300;
}

.reauth {
    padding: 5px;
    width: 100%;
    font-size: 14px;
    border-radius: 8px;
    border-width: 1px;
}

.button-container {
    margin-top: 20px;
    display: flex;
    justify-content: center;
}

.auth-button {
    padding: 2px;
}

.auth-button:first-child {
    margin-right: 10px;
}

.auth-button:nth-child(2) {
    margin-left: 10px;
}

.error {
    text-align: center;
    color: red;
}

/* ------------------------------- 5. Media Queries -------------------------------- */