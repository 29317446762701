/*////////////////////////
    Table of Contents
    1. Global Styles
    2. Typography
    3. Layout
        3.1 Reusables
        3.2 Other
    4. Areas
        4.1 Submit button
////////////////////////*/
/* ------------------------------- 1. Global Styles -------------------------------- */

/* ------------------------------- 2. Typography ----------------------------------- */

/* ------------------------------- 3. Layout --------------------------------------- */

/* ------------------------------- 4. Areas ---------------------------------------- */

/* --------------- 4.1 Submit button ------------------------- */

.button {
    margin: 25px 0 10px 0;
    width: 100%;
    height: 40px;
    border-radius: 8px;
    border-width: 1px;
    font-size: 16px;
    color: hsl(61 100% 50%);
    background: hsl(0, 100%, 50%);
}

.button:hover {
    cursor: pointer;
}