/*////////////////////////
    Table of Contents
    1. Global Styles
    2. Typography
    3. Layout
        3.1 Reusables
        3.2 Other
    4. Areas
        4.1 Profile information
        4.2 File upload card
        4.3 Change password popup card
    5. Media Queries
////////////////////////*/
/* ------------------------------- 1. Global Styles -------------------------------- */
/* ------------------------------- 2. Typography ----------------------------------- */
/* ------------------------------- 3. Layout --------------------------------------- */
/* --------------- 3.1 Reusables ----------------------- */
/* --------------- 3.2 Other --------------------------- */
/* ------------------------------- 4. Areas ---------------------------------------- */
/* --------------- 4.1 Profile information ------------------------- */

.content-wrapper {
    min-width: 100%;
    flex-basis: 90%;

    display: flex;
    flex-direction: column;
}

.top-section {
    flex-basis: 60%;
    display: flex;
    justify-content: space-around;
}

.profile-picture-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-basis: 40%;
    max-width: 200px;
}

.img {
    max-width: 100%;
    border-radius: 8px;
}

.edit-profile-button {
    margin-top: 5px;
    font-size: 12px;
    padding: 2px;
    border-radius: 2px;
    border-width: 1px;
}

.name-container {
    flex-basis: 60%;
    max-width: 200px;
    padding: 5px;

    display: flex;
    flex-direction: column;
    justify-content: center;
}

.name {
    margin: 0 0 0 11px;
}

.name:first-child {
    margin-bottom: 19px;
}

.profile-details {
    font-weight: 500;
}

.bottom-section {
    flex-basis: 40%;

    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.h3 {
    align-self: center;
    margin: 20px 0 5px 0;
}

.icon-container {
    min-width: 100%;
    margin-top: 20px;

    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

/* ------------------------------- 4.2 File upload card -------------------------------- */

.file-upload {
    width: 90%;
    max-width: 560px;
    padding: 10px 10px 10px 10px;
    top: 100px;
    background-color: rgba(255, 255, 255, 0.95);
    border-radius: 8px;
    border: solid rgba(3, 2, 2, 0.59) 2px;
    position: absolute;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.upload-title {
    margin-bottom: 10px;
}

.form {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
}

.button:first-child {
    margin-right: 20px;
}

.form > * {
    margin-bottom: 10px;
}

.input-file {
    max-width: 100%;
}

.button {
    padding: 2px;
}

.error {
    text-align: center;
    color: red;
}

/* ------------------------------- 4.3. Change password popup -------------------------------- */

.warning-popup {
    padding: 10px 10px 10px 10px;
    width: 80%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgb(255, 255, 255);
    border-radius: 8px;
    border: solid rgba(255, 0, 0, 0.59) 2px;
    position: absolute;
    text-align: center;

    display: flex;
    flex-direction: column;
    justify-content: center;
}

.warning-popup p {
    font-size: 14px;
}

.warning-popup p:nth-child(2) {
    margin: 5px 0 20px 0;
    font-weight: 300;
}

.reauth {
    padding: 5px;
    width: 100%;
    font-size: 14px;
    border-radius: 8px;
    border-width: 1px;
}

.reauth:first-child {
    margin-bottom: 5px;
}

.button-container {
    margin-top: 20px;
    display: flex;
    justify-content: center;
}

.button {
    padding: 2px;
}

.auth-button:first-child {
    margin-right: 10px;
}

.auth-button:nth-child(2) {
    margin-left: 10px;
}

/* ------------------------------- 5. Media Queries -------------------------------- */