/*////////////////////////
    Table of Contents
    1. Global Styles
    2. Typography
    3. Layout
        3.1 Reusables
        3.2 Other
    4. Areas
        4.1 Header
////////////////////////*/
/* ------------------------------- 1. Global Styles -------------------------------- */

/* ------------------------------- 2. Typography ----------------------------------- */

/* ------------------------------- 3. Layout --------------------------------------- */

/* ------------------------------- 4. Areas ---------------------------------------- */

/* --------------- 4.1 Header ------------------------- */
.header {
    display: flex;
    justify-content: center;
}

.content-container {
    flex-basis: 710px;
    min-height: 150px;
    background: url(../../assets/header_background_640×427.jpg);
    background-size: 100%;
    border-radius: 0 0 5px 5px;

    display: flex;
    position: relative;
}

.left-side {
    min-width: 50%;
    padding: 20px;

    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
}

.right-side {
    min-width: 50%;
    padding: 0 20px 10px 0;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    position: relative;
}

.img {
    cursor: pointer;
    position: absolute;
    top: 15px;
}

.p {
    font-size: 13px;
    color: hsl(0, 0%, 90%);
}

.h1 {
    font-size: 21px;
    font-weight: 700;
    color: hsl(0, 0%, 100%);
}

.date {
    position: absolute;
    bottom: 10px;
    color: hsl(0, 0%, 90%);
}

.line {
    border-bottom: 4px solid rgba(250, 255, 0, 0.5);
    position: absolute;
    bottom: 0;
    width: 100%;
}

.session-container {
    display: flex;
    align-items: center;
}

.session-inactive {
    font-weight: 700;
    color: #bd0303;
    text-shadow:
            0px 0px 20px rgba(0, 0, 0, 0.5),
            -2px 0px 0 rgba(0, 0, 0, 0.5),
            0px 2px 0 rgba(0, 0, 0, 0.5),
            2px 0px 0 rgba(0, 0, 0, 0.5),
            -2px -2px 0 rgba(0, 0, 0, 0.5),
            -2px 2px 0 rgba(0, 0, 0, 0.5),
            2px -2px 0 rgba(0, 0, 0, 0.5),
            2px 2px 0 rgba(0, 0, 0, 0.5),
            1px 1px 5px rgba(0, 0, 0, 0.5);
}

.session-active {
    font-weight: 700;
    color: rgb(2, 180, 2);
    text-shadow:
            0px 0px 20px rgba(0, 0, 0, 0.5),
            -2px 0px 0 rgba(0, 0, 0, 0.5),
            0px 2px 0 rgba(0, 0, 0, 0.5),
            2px 0px 0 rgba(0, 0, 0, 0.5),
            -2px -2px 0 rgba(0, 0, 0, 0.5),
            -2px 2px 0 rgba(0, 0, 0, 0.5),
            2px -2px 0 rgba(0, 0, 0, 0.5),
            2px 2px 0 rgba(0, 0, 0, 0.5),
            1px 1px 5px rgba(0, 0, 0, 0.5);
}

